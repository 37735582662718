/*
 * plagins
 */


// partials/placeholder.js

// partials/jquery.slimscroll.js

// partials/mycustomselect.js

// partials/hideShowPassword.min.js

// partials/jquery.dotdotdot.min.js

// https://github.com/PebbleRoad/combo-select
// partials/jquery.combo.select.js

// http://andreruffert.github.io/rangeslider.js
// partials/rangeslider.min.js


/*
 * Custom
 */
(function($) {

    $(document).ready(function(){

        // Header menu responsive
		$( window ).resize(function() {
			checkResponsiveMenu();
		});
		checkResponsiveMenu();


		$('#lang-dropdown-select-language').selectpicker({
		  style: 'btn-transparent',
		});

    });

    function checkResponsiveMenu(){
			if($( window ).width() < 992) {
				if($('.sv-collapse .container .hd-responsive-container').length <= 0) {
					$('.hd-responsive-container').clone().prependTo('.sv-collapse .container');
					$('.navbar-header > .hd-responsive-container').hide();
					$('.hd-language').clone().appendTo('.sv-collapse .container');
					$('.navbar-header > .hd-language').hide();
					
				}
			} else {
				if($('.sv-collapse .container .hd-responsive-container').length > 0) {
					$('.sv-collapse .container .hd-responsive-container').remove();
					$('.navbar-header > .hd-responsive-container').show();
					$('.sv-collapse .container .hd-language').remove();
					$('.navbar-header > .hd-language').show();
				}
			}
    }

})(jQuery);